import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        allowCost: "",
        itemsCats: [],
        branchesList: [],
        suppliersList: [],
        itemIdLogsHistory: ""
    }
    async setupItemsHistory() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table2').DataTable({
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const targetId = $("#boxForItemId").val();
                // console.log(targetId);
                await axios.post('/items_history_table', {itemId:targetId, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const itemId = resData[vCount].target_id;
                    const itemName = resData[vCount].target_name;
                    const operation = resData[vCount].operation;
                    const qty = resData[vCount].qty;
                    const qtyAfter = resData[vCount].qty_after;
                    const branch = resData[vCount].branch;
                    const author = resData[vCount].author;
                    const date = getMyDate(resData[vCount].date);

                    out.push([id, itemName, operation, qty, qtyAfter, branch, author, date]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
    }

    async setupDatatable() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        window.$('#table1').DataTable({
            'columnDefs': [
            { "width": "100%", "targets": 1 },
            { className: 'dt-left', targets: 1 },
            {'targets': [0],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }
            ],
            dom: 'lBfrtip',
            buttons: [
                {
                    extend: 'pdfHtml5',
                    text: 'Pdf',
                    filename: 'dt_custom_pdf',
                    pageSize: 'A4',
                    exportOptions: {
                        columns: [1, 3]
                    },
                    customize: function ( doc ) {
                        doc.content[1].table.widths = Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                        doc.defaultStyle.alignment = 'left';
                        doc.styles.tableHeader.alignment = 'center';
                        var rowCount = document.getElementById("table1").rows.length;
                        for (var ix = 0; ix < rowCount; ix++) {
                            doc.content[1].table.body[ix][1].alignment = 'center';
                        };
                        // doc.content[1].table.body[1][1].alignment = 'center';
                    }
                }, "excel", "copy"
            ],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const selectedCat = $("#selectedCat").val();
                const selectedSupplier = $("#selectedSupplier").val();
                const selectedBranch = $("#selectedBranch").val();
                const itemsStatus = $("#selectedItemsStatus").val();
                await axios.post('/items_table', {itemsStatus:itemsStatus, branch:selectedBranch, supplier:selectedSupplier, cat:selectedCat, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const name = resData[vCount].name;
                    const barcode = resData[vCount].barcode;
                    const cost = resData[vCount].cost;
                    var retail_price = resData[vCount].retail_price;
                    var wholesale_price = resData[vCount].wholesale_price;
                    const qty = resData[vCount].qty;
                    const date_add = getMyDate(resData[vCount].date_add);
                    const author = resData[vCount].author;
                    const last_author = resData[vCount].last_author;
                    const haveCodes = resData[vCount].have_codes ? resData[vCount].have_codes : "No";
                    const category = resData[vCount].category;
                    const warnQty = resData[vCount].warn_qty;
                    const supplier = resData[vCount].supplier;
                    const branch = resData[vCount].branch;
                    var secRetailPrice = resData[vCount].retail_price_2;
                    const status = resData[vCount].status;
                    const haveSns = resData[vCount].have_sns;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    if(status === "Active") {
                        if(self.state.user.access.includes("|8|")) {
                            buttons += "<a class='myAStyle' id='delete_admin' itemId='"+id+"' itemName='"+name+"' style='color: red !important;'>Trash</a>";
                        }
                        if(self.state.user.access.includes("|9|")) {
                            buttons += "<a class='myAStyle' id='edit_admin' itemId='"+id+"' itemName='"+name+"' itemBarcode='"+barcode+"' \
                                        itemCost='"+cost+"' itemRetail='"+retail_price+"' itemWholesale='"+wholesale_price+"' itemQty='"+qty+"' \
                                        itemCat='"+category+"' itemWarnQty='"+warnQty+"' itemSupplier='"+supplier+"' itemBranch='"+branch+"' itemSecRetail='"+secRetailPrice+"'>Update Data</a>";
                        }
                        if(self.state.user.access.includes("|16|")) {
                            buttons += "<a class='myAStyle' id='move_stock' itemId='"+id+"' itemName='"+name+"' itemBarcode='"+barcode+"' \
                                        itemCost='"+cost+"' itemRetail='"+retail_price+"' itemWholesale='"+wholesale_price+"' itemQty='"+qty+"' \
                                        itemCat='"+category+"' itemWarnQty='"+warnQty+"' itemSupplier='"+supplier+"' itemBranch='"+branch+"' itemSecRetail='"+secRetailPrice+"' haveSns='"+haveSns+"'>Move Stock</a>";
                        }
                        if(self.state.user.access.includes("|9|")) {
                            buttons += "<a class='myAStyle' id='update_stock' itemId='"+id+"' itemName='"+name+"' itemBarcode='"+barcode+"' \
                                        itemCost='"+cost+"' itemRetail='"+retail_price+"' itemWholesale='"+wholesale_price+"' itemQty='"+qty+"' \
                                        itemCat='"+category+"' itemWarnQty='"+warnQty+"' itemSupplier='"+supplier+"' itemBranch='"+branch+"'>Update Stock</a>";
                        }
                        if(self.state.user.access.includes("|21|")) {
                            buttons += "<a class='myAStyle' id='view_item_history' itemId='"+id+"' itemName='"+name+"'>History</a>";
                        }
                    } else if(status === "Trashed") {
                        if(self.state.user.access.includes("|65|")) {
                            buttons += "<a class='myAStyle' id='perm_delete_item' itemId='"+id+"' itemName='"+name+"' itemBranch='"+branch+"' style='color: red !important;'>Permanent Delete</a>";
                        }
                        if(self.state.user.access.includes("|66|")) {
                            buttons += "<a class='myAStyle' id='restore_trashed_item' itemId='"+id+"' itemName='"+name+"'>Restore</a>";
                        }
                    }
                    if(self.state.user.access.includes("|62|")) {
                        retail_price = "<input class='myInputStyle' type='number' value='"+retail_price+"' id='directEditRetailPrice_"+id+"' placeholder='' />";
                        secRetailPrice = "<input class='myInputStyle' type='number' value='"+secRetailPrice+"' id='directEditSecRetailPrice_"+id+"' placeholder='' />";
                        wholesale_price = "<input class='myInputStyle' type='number' value='"+wholesale_price+"' id='directEditWholeSalePrice_"+id+"' placeholder='' />";

                        retail_price += "<br><br><button class='myBtnStyle directUpdatePriceBtn' itemId='"+id+"' relateId='directEditRetailPrice_"+id+"' col='retail_price' tabIndex={0} type='button'>Set</button>";
                        secRetailPrice += "<br><br><button class='myBtnStyle directUpdatePriceBtn' itemId='"+id+"' relateId='directEditSecRetailPrice_"+id+"' col='retail_price_2' tabIndex={0} type='button'>Set</button>";
                        wholesale_price += "<br><br><button class='myBtnStyle directUpdatePriceBtn' itemId='"+id+"' relateId='directEditWholeSalePrice_"+id+"' col='wholesale_price' tabIndex={0} type='button'>Set</button>";
                    }
                    buttons += "</div></div>";
                    
                    var totalCost = "";
                    if(cost && qty && !isNaN(cost) && !isNaN(qty)) {
                        totalCost = String(parseFloat(cost) * parseFloat(qty));
                    }

                    out.push([id, name, String(qty), branch, barcode, cost, category, retail_price, secRetailPrice, wholesale_price, author, haveCodes, warnQty, supplier, totalCost, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
                $("#deleteSelectedBtn").hide();
            }
        });
        if(self.state.user.access.includes("|61|")) {
            const table = window.$('#table1').DataTable();
            table.off("click", "tbody tr");
            table.on('click', 'tbody tr', function (e) {
                if(e.target._DT_CellIndex) {
                    const col = e.target._DT_CellIndex.column;
                    if(col === 1) {
                        e.currentTarget.classList.toggle('selected');
                        const len = table.rows('.selected').data().length;
                        $("#deleteSelectedBtn").hide();
                        if(len > 0) {
                            $("#deleteSelectedBtn").show();
                        }
                    }
                }
            });
        }
        $(document).off("click", "#perm_delete_item");
        $(document).on("click", "#perm_delete_item", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBranch = $(this).attr("itemBranch");
            self.setState({adminToDel:itemName, adminIdToDel:itemId, itemBranchVar:itemBranch});
            window.$("#permDeleteItemModal").modal("show");
        });
        $(document).off("click", "#restore_trashed_item");
        $(document).on("click", "#restore_trashed_item", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            window.toastr["info"]("Restoring...");
            axios.post("/restore_trashed_item", {id:itemId, name:itemName}).then((ok)=>{
                window.toastr.remove();
                window.toastr[ok.data.status](ok.data.msg);
                window.$('#table1').DataTable().ajax.reload(null, false);
            });
        });
        $(document).off("click", ".directUpdatePriceBtn");
        $(document).on("click", ".directUpdatePriceBtn", function() {
            const itemId = $(this).attr("itemId");
            const relateId = $(this).attr("relateId");
            const col = $(this).attr("col");
            const newPrice = $("#"+relateId).val();
            window.toastr["info"]("Updating...");
            axios.post("/direct_update_price", {id:itemId, col:col, price:newPrice}).then((ok)=>{
                window.toastr.remove();
                window.toastr[ok.data.status](ok.data.msg);
                window.$('#table1').DataTable().ajax.reload(null, false);
            });
        });
        $(document).off("click", "#delete_admin");
        $(document).on("click", "#delete_admin", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#deleteAdminModal").modal("show");
        });
        $(document).off("click", "#view_item_history");
        $(document).on("click", "#view_item_history", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId, itemIdLogsHistory:itemId});
            $("#boxForItemId").val(itemId);
            window.$("#itemLogsHistory").modal("show");
            self.setupItemsHistory();
        });
        $(document).off("click", "#edit_admin");
        $(document).on("click", "#edit_admin", async function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBarcode = $(this).attr("itemBarcode");
            const itemCost = $(this).attr("itemCost");
            const itemRetail = $(this).attr("itemRetail");
            const itemSecRetail = $(this).attr("itemSecRetail");
            const itemWholesale = $(this).attr("itemWholesale");
            const itemQty = $(this).attr("itemQty");
            const itemCat = $(this).attr("itemCat");
            const itemWarnQty = $(this).attr("itemWarnQty");
            const itemSupplier = $(this).attr("itemSupplier");
            const itemBranch = $(this).attr("itemBranch");

            await self.setupItemModal("#updateItemModal");
            $("#inItemCat2").val(itemCat);
            $("#inItemName2").val(itemName);
            $("#inItemBarcode2").val(itemBarcode);
            $("#inItemCost2").val(itemCost);
            $("#inItemRetailPrice2").val(itemRetail);
            $("#inItemSecRetailPrice2").val(itemSecRetail);
            $("#inItemWholeSalePrice2").val(itemWholesale);
            $("#inItemQty2").val(itemQty);
            $("#inItemWarnQty2").val(itemWarnQty);
            if($("#inSuppliers2").val() !== "No Permissions" && $("#inSuppliers2").val() !== "No Suppliers yet") {
                $("#inSuppliers2").val(itemSupplier);
            }

            if($("#inBranches2").val() !== "No Permissions" && $("#inBranches2").val() !== "No Branches yet") {
                $("#inBranches2").val(itemBranch);
            }

            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            // window.$("#deleteAdminModal").modal("show");
        });

        $(document).off("click", "#move_stock");
        $(document).on("click", "#move_stock", async function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBarcode = $(this).attr("itemBarcode");
            const itemCost = $(this).attr("itemCost");
            const itemRetail = $(this).attr("itemRetail");
            const itemSecRetail = $(this).attr("itemSecRetail");
            const itemWholesale = $(this).attr("itemWholesale");
            const itemQty = $(this).attr("itemQty");
            const itemCat = $(this).attr("itemCat");
            const itemWarnQty = $(this).attr("itemWarnQty");
            const itemSupplier = $(this).attr("itemSupplier");
            const itemBranch = $(this).attr("itemBranch");
            const haveSns = $(this).attr("haveSns");
            

            await self.setupItemModal("#moveStockModal");
            $("#curItemName").html(itemName);
            $("#curItemCost").html(itemCost);
            $("#curItemRetail").html(itemRetail);
            $("#curItemSecRetail").html(itemSecRetail);
            $("#curItemWholesale").html(itemWholesale);
            $("#curItemQty").html(itemQty);
            $("#inItemQtyToMove").val("");
            $("#curItemBranch").html(itemBranch);
            $("#inBranches3").val(itemBranch);
            $("#assignSnsAreaId3").hide();
            if(haveSns === "yes") {
                $("#assignSnsAreaId3").show();
            }

            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            // window.$("#deleteAdminModal").modal("show");
        });

        $(document).off("click", "#update_stock");
        $(document).on("click", "#update_stock", async function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemCost = $(this).attr("itemCost");
            const itemQty = $(this).attr("itemQty");
            const itemCat = $(this).attr("itemCat");
            const itemBranch = $(this).attr("itemBranch");

            $("#curItemName2").html(itemName);
            $("#curItemCat2").html(itemCat);
            $("#curItemCost2").html(itemCost);
            $("#curItemQty2").html(itemQty);
            $("#curItemBranch2").html(itemBranch);

            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#updateStockModal").modal("show");
        });
    }

    setupItemModal = async(modal) => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_cats").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({itemsCats:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({itemsCats:data});
            }
            // console.log(ok.data);
        })
        await axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({branchesList:data});
            }
        })
        await axios.post("/get_suppliers").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({suppliersList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({suppliersList:data});
            }
        })
        window.toastr.clear();
        window.$(modal).modal("show");
    }

    componentDidMount() {
        axios.post("/is_authed").then(async(ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            await this.setStateSynchronous({user:ok.data});
            if(ok.data.access.includes("|10|")) {
                this.setState({allowCost:true});
            } else {
                this.setState({allowCost:false});
            }
            if(!ok.data.access.includes("|6|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#items_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            $("[name='table1_length']").append("<option value='1000'>1000</otpion>");

            if(ok.data.access.includes("|7|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                            this.setupItemModal("#addItemModal");
                        }}>+ Add</button>
                    </>
                ]})
            }
            axios.post("/get_cats").then((ok)=>{
                if(ok.data.status == "error") {
                    this.setState({itemsCats:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({itemsCats:data});
                }
            })
            axios.post("/get_suppliers").then((ok)=>{
                if(ok.data.status == "error") {
                    this.setState({suppliersList:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({suppliersList:data});
                }
            })
            axios.post("/get_branches").then((ok)=>{
                if(ok.data.status == "error") {
                    this.setState({branchesList:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({branchesList:data});
                }
            })
            // $("#clickAddItemId").on("keyup", (e) => {
            //     console.log(e.key);
            // })
        })
    }
    addItem = () => {
        const cat = $("#inItemCat").val();
        const name = $("#inItemName").val();
        const barcode = $("#inItemBarcode").val();
        const cost = $("#inItemCost").val();
        const retailPrice = $("#inItemRetailPrice").val();
        const secRetailPrice = $("#inItemSecRetailPrice").val();
        const wholeSalePrice = $("#inItemWholeSalePrice").val();
        const qty = $("#inItemQty").val();
        let warnQty = $("#inItemWarnQty").val();
        let supplier = $("#inSuppliers").val();
        let branch = $("#inBranches").val();

        if(!cat) {
            window.toastr["error"]("Category Field Missed");
            return;
        }
        if(!name) {
            window.toastr["error"]("Item Name Field Missed");
            return;
        }
        // if(!barcode) {
        //     window.toastr["error"]("Item Barcode Field Missed");
        //     return;
        // }
        if(!cost) {
            window.toastr["error"]("Item Cost Field Missed");
            return;
        }
        if(!retailPrice) {
            window.toastr["error"]("Item Retail Price Field Missed");
            return;
        }
        if(!secRetailPrice) {
            window.toastr["error"]("Item Second Retail Price Field Missed");
            return;
        }
        if(!wholeSalePrice) {
            window.toastr["error"]("Item Wholesale Price Field Missed");
            return;
        }
        if(!qty) {
            window.toastr["error"]("Item Qty Field Missed");
            return;
        }
        // if(!warnQty) {
        //     window.toastr["error"]("Item Warning Qty Field Missed");
        //     return;
        // }
        // if(!supplier) {
        //     window.toastr["error"]("Supplier Field Missed");
        //     return;
        // }
        if(!branch) {
            window.toastr["error"]("Branch Field Missed");
            return;
        }

        if(supplier === "No Permissions" | supplier === "No Suppliers yet") {
            supplier = "";
        }
        if(branch === "No Permissions" | branch === "No Suppliers yet") {
            window.toastr["error"]("Invalid Branch Value");
            return;
        }

        var outAssignedData = [];
        if(document.getElementById("assignCodesId").checked) {
            const areaData = $("#setCodesArea").val().split("\n");
            var count = 0;
            for(var i = 0; i < areaData.length; ++i) {
                var line = areaData[i].replace(/\n/g, "");
                if(line) {
                    count += 1;
                    outAssignedData.push(line);
                }
            }
            if(parseFloat(count) !== parseFloat(qty)) {
                window.toastr["error"]("Assigned Codes Count["+count+"] not Equal Item Qty["+qty+"]");
                return;
            }
        }
        var printOnSale = "no";
        if(document.getElementById("printOnSaleId").checked) {
            printOnSale = "yes";
        }
        
        var outAssignedSns = [];
        if(document.getElementById("assignSnsId").checked) {
            const areaData = $("#setSnsArea").val().split("\n");
            var count = 0;
            for(var i = 0; i < areaData.length; ++i) {
                var line = areaData[i].replace(/\n/g, "").replace(/ /g, "");
                if(line) {
                    const spData2 = line.split("|");
                    for(var i2 = 0; i2 < spData2.length; ++i2) {
                        if(spData2[i2] && outAssignedSns.toString().includes(spData2[i2])) {
                            window.toastr["error"]("SNS Code ["+spData2[i2]+"] is duplicated");
                            return;
                        }
                    }
                    count += 1;
                    if(line.includes("|")) {
                        outAssignedSns.push(line);
                    } else {
                        outAssignedSns.push("|"+line+"|");
                    }
                }
            }
            if(parseFloat(count) !== parseFloat(qty)) {
                window.toastr["error"]("Assigned SNS Count["+count+"] not Equal Item Qty["+qty+"]");
                return;
            }
        }

        axios.post("/add_item", {sns:outAssignedSns, print:printOnSale, codes:outAssignedData, cat:cat, name:name, barcode:barcode, cost:cost, retailPrice:retailPrice, secRetailPrice:secRetailPrice, wholeSalePrice:wholeSalePrice, qty:qty, warnQty:warnQty, supplier:supplier, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addItemModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })

    }

    updateItem = () => {
        const cat = $("#inItemCat2").val();
        const name = $("#inItemName2").val();
        const barcode = $("#inItemBarcode2").val();
        const cost = $("#inItemCost2").val();
        const retailPrice = $("#inItemRetailPrice2").val();
        const secRetailPrice = $("#inItemSecRetailPrice2").val();
        const wholeSalePrice = $("#inItemWholeSalePrice2").val();
        // const qty = $("#inItemQty2").val();
        let warnQty = $("#inItemWarnQty2").val();
        let supplier = $("#inSuppliers2").val();
        let branch = $("#inBranches2").val();

        if(!cat) {
            window.toastr["error"]("Category Field Missed");
            return;
        }
        if(!name) {
            window.toastr["error"]("Item Name Field Missed");
            return;
        }
        // if(!barcode) {
        //     window.toastr["error"]("Item Barcode Field Missed");
        //     return;
        // }
        if(!cost) {
            window.toastr["error"]("Item Cost Field Missed");
            return;
        }
        if(!retailPrice) {
            window.toastr["error"]("Item Retail Price Field Missed");
            return;
        }
        if(!wholeSalePrice) {
            window.toastr["error"]("Item Wholesale Price Field Missed");
            return;
        }
        // if(!qty) {
        //     window.toastr["error"]("Item Qty Field Missed");
        //     return;
        // }
        // if(!warnQty) {
        //     window.toastr["error"]("Item Warning Qty Field Missed");
        //     return;
        // }
        // if(!supplier) {
        //     window.toastr["error"]("Supplier Field Missed");
        //     return;
        // }
        if(!branch) {
            window.toastr["error"]("Branch Field Missed");
            return;
        }

        if(supplier === "No Permissions" | supplier === "No Suppliers yet") {
            supplier = "";
        }
        if(branch === "No Permissions" | branch === "No Suppliers yet") {
            window.toastr["error"]("Invalid Branch Value");
            return;
        }
        axios.post("/update_item", {id:this.state.adminIdToDel, cat:cat, name:name, barcode:barcode, cost:cost, retailPrice:retailPrice, secRetailPrice:secRetailPrice, wholeSalePrice:wholeSalePrice, /*qty:qty, */warnQty:warnQty, supplier:supplier, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateItemModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })

    }

    deleteItem = () => {
        axios.post("/del_item", {id:this.state.adminIdToDel}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    moveItemStock = () => {
        const id = this.state.adminIdToDel;
        const curBranch = $("#curItemBranch").html();
        const newBranch = $("#inBranches3").val();
        const qty = $("#inItemQtyToMove").val();
        if(!qty) {
            window.toastr["error"]("Invalid Qty to move");
            return;
        }
        if(parseFloat(qty) < 1) {
            window.toastr["error"]("Qty Number must be greater than 0");
            return;
        }
        if(curBranch === newBranch) {
            window.toastr["error"]("New Branch must be different than current Branch");
            return;
        }
        
        var outAssignedSns = [];
        if($("#assignSnsAreaId3").is(":visible")) {
            const areaData = $("#setSnsImei1Area").val().split("\n");
            var count = 0;
            for(var i = 0; i < areaData.length; ++i) {
                var line = areaData[i].replace(/\n/g, "").replace(/ /g, "");
                if(line) {
                    count += 1;
                    outAssignedSns.push(line);
                }
            }
            if(parseFloat(count) !== parseFloat(qty)) {
                window.toastr["error"]("Assigned SNS Count["+count+"] not Equal Item Qty["+qty+"]");
                return;
            }
        }
        
        axios.post("/move_item_stock", {sns:outAssignedSns, id:id, cur_branch:curBranch, new_branch:newBranch, qty:qty}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#moveStockModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })

    }

    updateItemStock = (type) => {
        const id = this.state.adminIdToDel;
        const qty = $("#inItemQtyToChange").val();
        const newCost = $("#inItemCostToChange").val();
        if(!qty) {
            window.toastr["error"]("Invalid Qty to move");
            return;
        }
        if(parseFloat(qty) < 1) {
            window.toastr["error"]("Qty Number must be greater than 0");
            return;
        }
        var api = "/add_stock_qty";
        if(type === "-") {
            api = "/remove_stock_qty";
        }
        var outAssignedData = [];
        if(document.getElementById("assignCodesId2").checked) {
            const areaData = $("#setCodesArea2").val().split("\n");
            var count = 0;
            for(var i = 0; i < areaData.length; ++i) {
                var line = areaData[i].replace(/\n/g, "");
                if(line) {
                    count += 1;
                    outAssignedData.push(line);
                }
            }
            if(parseFloat(count) !== parseFloat(qty)) {
                window.toastr["error"]("Assigned Codes Count["+count+"] not Equal Item Qty["+qty+"]");
                return;
            }
        }
        var printOnSale = "no";
        if(document.getElementById("printOnSaleId2").checked) {
            printOnSale = "yes";
        }
        
        var outAssignedSns = [];
        if(document.getElementById("assignSnsId2").checked) {
            const areaData = $("#setSnsArea2").val().split("\n");
            var count = 0;
            for(var i = 0; i < areaData.length; ++i) {
                var line = areaData[i].replace(/\n/g, "").replace(/ /g, "");
                if(line) {
                    const spData2 = line.split("|");
                    for(var i2 = 0; i2 < spData2.length; ++i2) {
                        if(spData2[i2] && outAssignedSns.toString().includes(spData2[i2])) {
                            window.toastr["error"]("SNS Code ["+spData2[i2]+"] is duplicated");
                            return;
                        }
                    }
                    count += 1;
                    if(line.includes("|")) {
                        outAssignedSns.push(line);
                    } else {
                        outAssignedSns.push("|"+line+"|");
                    }
                }
            }
            if(parseFloat(count) !== parseFloat(qty)) {
                window.toastr["error"]("Assigned SNS Count["+count+"] not Equal Item Qty["+qty+"]");
                return;
            }
        }
        
        axios.post(api, {sns:outAssignedSns, print:printOnSale, codes:outAssignedData, id:id, qty:qty, ncost:newCost}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateStockModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }
    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }
    addCat = () => {
        const parentCat = $("#inParentCat2").val();
        const catName = $("#inCatName").val();
        axios.post("/add_cat", {parent:parentCat, name:catName}).then(async(ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addCatModal").modal("hide");
                await axios.post("/get_cats").then(async(ok)=>{
                    if(ok.data.status == "error") {
                        this.setState({itemsCats:[
                            <option value="">{ok.data.msg}</option>
                        ]});
                    } else {
                        var data = [];
                        for(var i = 0; i < ok.data.msg.length; ++i) {
                            data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                        }
                        await this.setStateSynchronous({itemsCats:data});
                        window.$("select").select2();
                    }
                });
            }
        })
    }
    
    bulkDeleteItems = () => {
        const table = window.$('#table1').DataTable();
        const len = table.rows('.selected').data().length;
        if(len > 0) {
            var data = [];
            var ids = [];
            for(var i = 0; i < len; ++i) {
                const itemId = table.rows('.selected').data()[i][0];
                const itemName = table.rows('.selected').data()[i][1];
                const itemQty = table.rows('.selected').data()[i][2];
                const itemBranch = table.rows('.selected').data()[i][3];
                data.push([itemId, itemName, itemQty, itemBranch]);
                ids.push(itemId);
            }
            axios.post("/delete_bulk_items", {ids:ids, data:data}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                window.$("#confirmDeleteSelectedItems").modal("hide");
                if(ok.data.status === "success") {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }
            });
        } else {
            $("#deleteSelectedBtn").hide();
        }
    }

    doPermDeleteItem = () => {
        const id = this.state.adminIdToDel;
        const name = this.state.adminToDel;
        window.toastr["info"]("Removing...");
        axios.post("/permanent_del_item", {id:id, name:name}).then((ok)=>{
            window.toastr.remove();
            window.toastr[ok.data.status](ok.data.msg);
            window.$("#permDeleteItemModal").modal("hide");
            window.$('#table1').DataTable().ajax.reload(null, false);
        });
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="permDeleteItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Permanent Delete Item
                        </div>
                        <div class="modal-body">
                            Are you sure you want to PERMANENT Delete ({this.state.adminToDel}) in Branch ({this.state.itemBranchVar}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.doPermDeleteItem();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#permDeleteItemModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="confirmDeleteSelectedItems" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Bulk Trash Items
                        </div>
                        <div class="modal-body">
                            Are you sure you want to Trash the selected items ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.bulkDeleteItems();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#confirmDeleteSelectedItems").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteAdminModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Item
                        </div>
                        <div class="modal-body">
                            Are you sure you want to Trash item ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteItem();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteAdminModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="itemLogsHistory" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" style={{maxWidth: "95%"}}>
                    <div class="modal-content" style={{backgroundColor: "gainsboro"}}>
                        <div class="modal-header">
                            Logs History of ({this.state.adminToDel}) Stock
                        </div>
                        <div class="modal-body">
                            <input id="boxForItemId" style={{display: "none"}} />
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                window.$('#table2').DataTable().ajax.reload(null, false);
                            }}>Refresh</button>
                            <br/>
                            <br/>
                            <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Item Name</th>
                                        <th>Operation</th>
                                        <th>Qty</th>
                                        <th>Qty After Operation</th>
                                        <th>Branch</th>
                                        <th>Author</th>
                                        <th>Date</th>
                                        {/* <th>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {/* <td></td> */}
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateStockModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Update ({this.state.adminToDel}) Stock Qty
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Item ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td id="curItemName2"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Category:</th>
                                        <td id="curItemCat2"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Cost:</th>
                                        <td id="curItemCost2"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Qty:</th>
                                        <td id="curItemQty2"></td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td id="curItemBranch2"></td>
                                    </tr>
                                    <tr>
                                        <th>Qty to Change:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemQtyToChange" type="number" placeholder=""/>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="assignCodesId2" onChange={() => {
                                                    if(document.getElementById("assignCodesId2").checked) {
                                                        $("#assignCodesAreaId2").show();
                                                    } else {
                                                        $("#assignCodesAreaId2").hide();
                                                    }
                                                }} />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="assignCodesId2">Assign Codes</label>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="assignSnsId2" onChange={() => {
                                                    if(document.getElementById("assignSnsId2").checked) {
                                                        $("#assignSnsAreaId2").show();
                                                    } else {
                                                        $("#assignSnsAreaId2").hide();
                                                    }
                                                }} />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="assignSnsId2">Assign SNS</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr id="assignSnsAreaId2" style={{display: "none"}}>
                                        <th>SNS (SN|IMEI1|IMEI2)</th>
                                        <td>
                                            <textarea id="setSnsArea2" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                        </td>
                                    </tr>
                                    <tr id="assignCodesAreaId2" style={{display: "none"}}>
                                        <th>Codes</th>
                                        <td>
                                            <textarea id="setCodesArea2" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="printOnSaleId2" />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="printOnSaleId2">Print When Sale</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>New Cost:</th>
                                        <td><input class="myInputStyle" id="inItemCostToChange" type="number" placeholder="Leave Empty to keep current Cost"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.updateItemStock("-");
                            }}>Remove Qty</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateItemStock("+");
                            }}>Add Qty</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="moveStockModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Move ({this.state.adminToDel}) Stock from Branch to another
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Current Item Name:</th>
                                        <td id="curItemName"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Cost:</th>
                                        <td id="curItemCost"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Retail Price:</th>
                                        <td id="curItemRetail"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Second Retail Price:</th>
                                        <td id="curItemSecRetail"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Wholesale Price:</th>
                                        <td id="curItemWholesale"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Qty:</th>
                                        <td id="curItemQty"></td>
                                    </tr>
                                    <tr>
                                        <th>Currenty Branch:</th>
                                        <td id="curItemBranch"></td>
                                    </tr>
                                    <tr>
                                        <th>Qty to Move:</th>
                                        <td><input class="myInputStyle" id="inItemQtyToMove" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Branch to Move Stock to:</th>
                                        <td>
                                            <select id="inBranches3" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr id="assignSnsAreaId3" style={{display: "none"}}>
                                        <th>IMEI 1 List (Line by Line):</th>
                                        <td>
                                            <textarea id="setSnsImei1Area" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='moveItemStockBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.moveItemStock();
                            }}>Move</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" tabIndex="0" onKeyDown={(e) => {
                    if($("#inItemBarcode2").is(":focus")) {
                        return;
                    }
                    if(e.key === "Enter") {
                        this.updateItem();
                    }
                }}>
                    <div class="modal-content" style={{width: "max-content"}}>
                        <div class="modal-header">
                            Update ({this.state.adminToDel}) Item
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Item Category:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="inItemCat2" className="myInputStyle">
                                                {this.state.itemsCats}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' id="makeNewCategory2" tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                window.$("#addCatModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#addCatModal").on("shown.bs.modal", function () {
                                                    $("#updateItemModal").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#updateItemModal").css({zIndex: "1050"});
                                                });
                                                window.$("#addCatModal").modal("show");
                                            }}>Add Category</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td><input class="myInputStyle" id="inItemName2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Barcode:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemBarcode2" placeholder=""/>
                                            &nbsp;
                                            <button class='myBtnStyle' id='updateBarcodeBtn' tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                axios.post("/get_new_barcode").then((ok)=>{
                                                    if(ok.data.status === "success") {
                                                        $("#inItemBarcode2").val(ok.data.msg);
                                                    } else {
                                                        window.toastr["error"](ok.data.msg);
                                                    }
                                                })
                                            }}>Update Barcode</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Cost:</th>
                                        <td><input class="myInputStyle" id="inItemCost2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Retail Price:</th>
                                        <td><input class="myInputStyle" id="inItemRetailPrice2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Second Retail Price:</th>
                                        <td><input class="myInputStyle" id="inItemSecRetailPrice2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Wholesale Price:</th>
                                        <td><input class="myInputStyle" id="inItemWholeSalePrice2" placeholder=""/></td>
                                    </tr>
                                    <tr style={{display: "none"}}>
                                        <th>Item Qty:</th>
                                        <td><input class="myInputStyle" id="inItemQty2" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Warning Qty:</th>
                                        <td><input class="myInputStyle" id="inItemWarnQty2" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Supplier:</th>
                                        <td>
                                            <select id="inSuppliers2" className="myInputStyle">
                                                <option value="">{"<Without Supplier>"}</option>
                                                {this.state.suppliersList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranches2" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='updateItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateItem();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addCatModal" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Parent Category:</th>
                                        <td>
                                            <select id="inParentCat2" className="myInputStyle">
                                                <option value="">{"<Make as Main Category>"}</option>
                                                {this.state.itemsCats}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Category Name:</th>
                                        <td><input class="myInputStyle" id="inCatName"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addCat();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" tabIndex="0" onKeyDown={(e) => {
                    if($("#setCodesArea").is(":focus")) {
                        return;
                    }
                    if($("#setSnsArea").is(":focus")) {
                        return;
                    }
                    if(e.key === "Enter") {
                        this.addItem();
                    }
                }}>
                    <div class="modal-content" style={{width: "max-content"}}>
                        <div class="modal-header">
                            Add new Item
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Item Category:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="inItemCat" className="myInputStyle">
                                                {this.state.itemsCats}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' id="makeNewCategory" tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                window.$("#addCatModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#addCatModal").on("shown.bs.modal", function () {
                                                    $("#addItemModal").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#addItemModal").css({zIndex: "1050"});
                                                });
                                                window.$("#addCatModal").modal("show");
                                            }}>Add Category</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemName" placeholder=""/>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="assignCodesId" onChange={() => {
                                                    if(document.getElementById("assignCodesId").checked) {
                                                        $("#assignCodesAreaId").show();
                                                    } else {
                                                        $("#assignCodesAreaId").hide();
                                                    }
                                                }} />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="assignCodesId">Assign Codes</label>
                                                
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="assignSnsId" onChange={() => {
                                                    if(document.getElementById("assignSnsId").checked) {
                                                        $("#assignSnsAreaId").show();
                                                    } else {
                                                        $("#assignSnsAreaId").hide();
                                                    }
                                                }} />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="assignSnsId">Assign SNS</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr id="assignSnsAreaId" style={{display: "none"}}>
                                        <th>SNS (SN|IMEI1|IMEI2)</th>
                                        <td>
                                            <textarea id="setSnsArea" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                        </td>
                                    </tr>
                                    <tr id="assignCodesAreaId" style={{display: "none"}}>
                                        <th>Codes</th>
                                        <td>
                                            <textarea id="setCodesArea" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="printOnSaleId" />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="printOnSaleId">Print When Sale</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Barcode:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemBarcode" placeholder=""/>
                                            &nbsp;
                                            <button class='myBtnStyle' id='genBarcodeBtn' tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                axios.post("/get_new_barcode").then((ok)=>{
                                                    if(ok.data.status === "success") {
                                                        $("#inItemBarcode").val(ok.data.msg);
                                                    } else {
                                                        window.toastr["error"](ok.data.msg);
                                                    }
                                                })
                                            }}>Generate Barcode</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Cost:</th>
                                        <td><input class="myInputStyle" id="inItemCost" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Retail Price:</th>
                                        <td><input class="myInputStyle" id="inItemRetailPrice" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Second Retail Price:</th>
                                        <td><input class="myInputStyle" id="inItemSecRetailPrice" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Wholesale Price:</th>
                                        <td><input class="myInputStyle" id="inItemWholeSalePrice" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Qty:</th>
                                        <td><input class="myInputStyle" id="inItemQty" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Warning Qty:</th>
                                        <td><input class="myInputStyle" id="inItemWarnQty" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Supplier:</th>
                                        <td>
                                            <select id="inSuppliers" className="myInputStyle">
                                            <option value="">{"<Without Supplier>"}</option>
                                                {this.state.suppliersList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranches" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addItem();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Items Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>

                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="selectedBranch" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Category:</th>
                            <td>
                                <select id="selectedCat" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.itemsCats}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Supplier:</th>
                            <td>
                                <select id="selectedSupplier" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.suppliersList}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Show Items Status:</th>
                            <td>
                                <select id="selectedItemsStatus" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="Active">Active</option>
                                    <option value="Trashed">Trashed</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>

                {/* <label>Category: </label>
                &nbsp;
                <select id="selectedCat" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>
                    {this.state.itemsCats}
                </select>
                <br/>

                <label>Supplier: </label>
                &nbsp;
                <select id="selectedSupplier" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>
                    {this.state.suppliersList}
                </select>
                <br/>
                <br/> */}
                <input class="myInputStyle" id="inPageNo" style={{width: "10%"}} placeholder="Jump to Page..." onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        window.$('#table1').DataTable().page(parseFloat($("#inPageNo").val()) - 1).draw("page");
                    }
                }} />
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%", fontSize: "smaller"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th style={{textAlign: "start"}}>Name</th>
                            <th>Qty</th>
                            <th>Branch</th>
                            <th>Barcode</th>
                            <th>Cost</th>
                            <th>Category</th>
                            <th>Retail Price</th>
                            <th>Second Retail Price</th>
                            <th>Wholesale Price</th>
                            <th>Added By</th>
                            <th>Assigned Codes</th>
                            <th>Warning Qty</th>
                            <th>Supplier</th>
                            <th>Total Cost</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div id="deleteSelectedBtn" style={{display: "none"}}>
                    <button class="myBtnStyle" tabindex="0" type="button" onClick={() => {
                        window.$("#confirmDeleteSelectedItems").modal("show");
                    }}>Trash Selected</button>
                    <br/>
                </div>
            </div>
            </>
        )
    }
}

export default main;
